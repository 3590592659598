<template>
  <div>
    <v-breadcrumbs class="py-2" :items="breadcrumbs">
      <template v-slot:item="{ item }">
        <router-link :to="item.href" class="v-breadcrumbs__item">
          {{ item.text }}
        </router-link>
      </template>
    </v-breadcrumbs>
    <v-card outlined class="mt-1">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          {{ record.name }}
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text v-if="record._id">
        <v-row dense>
          <v-col cols="12" sm="6">
            <div>{{ $t("Short name") }}</div>
            <div class="text-h3">{{ record.short_name }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("Type") }}</div>
            <div class="text-h3">{{ record.event_type }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Summary") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <div>
              {{ record.summary }}
            </div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("Start date") }}</div>
            <div class="text-h3">{{ record.start_date }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("End date") }}</div>
            <div class="text-h3">{{ record.end_date }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("No participants") }}</div>
            <div class="text-h3">{{ record.participants_no }}</div>
          </v-col>
          <v-col cols="12" sm="6">
            <div>{{ $t("No participants with paper") }}</div>
            <div class="text-h3">{{ record.participants_with_paper_no }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Participants") }}</v-subheader>
          </v-col>
          <v-col v-for="(participant, ind) in record.participants" :key="'participant' + ind" cols="12" sm="6">
            <div>{{ participant.participant_type }}</div>
            <div class="text-h3">{{ participant.participants_no }}</div>
          </v-col>
          <v-col cols="12">
            <v-subheader>{{ $t("Papers") }}</v-subheader>
          </v-col>
          <v-col cols="12">
            <v-sheet v-for="(paper, ind) in record.papers" :key="'paper' + ind" elevation="1" class="pa-3 mb-3">
              <v-row dense>
                <v-col cols="12" sm="8">
                  <div>{{ $t("Title") }}</div>
                  <div class="text-h3">{{ paper.title }}</div>
                </v-col>
                <v-col cols="12" sm="4">
                  <div>{{ $t("Short name") }}</div>
                  <div class="text-h3">{{ paper.short_name }}</div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>{{ $t("Description") }}</v-subheader>
                </v-col>
                <v-col cols="12">{{ paper.description }} </v-col>
                <v-col cols="12">
                  <v-subheader>{{ $t("Teachers") }}</v-subheader>
                </v-col>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template #default>
                      <thead>
                        <th>{{ $t("Author") }}</th>
                        <th>{{ $t("Institution") }}</th>
                      </thead>
                      <tbody>
                        <tr v-for="(teacher, indt) in paper.teachers" :key="'teacher' + indt">
                          <td class="text-h4">{{ teacher.name }}</td>
                          <td class="text-h4">{{ teacher.institution }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
                <v-col cols="12">
                  <v-subheader>{{ $t("Authors") }}</v-subheader>
                </v-col>
                <v-col cols="12">
                  <v-simple-table dense>
                    <template #default>
                      <thead>
                        <th>{{ $t("Author") }}</th>
                        <th>{{ $t("Institution") }}</th>
                      </thead>
                      <tbody>
                        <tr v-for="(author, indt) in paper.authors" :key="'author' + indt">
                          <td class="text-h4">{{ author.name }}</td>
                          <td class="text-h4">{{ author.institution }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      id: "",
      record: {},
      loading: true,
      path: "",
      render: 1,
      dictionary: {},
      breadcrumbs: [
        {
          text: "Home",
          disabled: false,
          href: "/"
        },
        {
          text: "Conferences events",
          disabled: false,
          href: "/conferences-events"
        }
      ]
    }
  },
  computed: {},
  watch: {
    $route(to) {
      this.refreshPage()
    }
  },
  created() {
    this.$log("routec ", this.$route)
    this.refreshPage()
  },
  methods: {
    refreshPage() {
      if (this.$route.name == "ConferencesEvent") {
        this.id = this.$route.params.pathMatch
        this.getRecords()
      }
    },
    getRecords() {
      axios
        .get("infuni_conferences_events/" + this.id)
        .then(response => {
          this.record = response.data
          //   this.record.employment_years.sort((a, b) => {
          //     return b.year - a.year
          //   })
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    resolvePath(path, obj) {
      return path.split(".").reduce(function(prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    }
  }
}
</script>
<style scoped>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
</style>
